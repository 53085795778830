@import "../variables";

body,
.card,
.card-header,
.hero {
    background-color: var(--skin-background-color-1);
    font-family: var(--skin-body-font);
    color: $text-color;
}
body {
    font-size: 14px;
}
.hero {
    border-bottom-color: var(--skin-background-color-1);
}

.main-menu {
    background-color: $ocean;
}

.banner-color {
    background-color: var(--skin-banner-background-color-1);
}

.header-banner {
	background-color: #FFF6EA;
   
    color: #495057;
}

.header-banner .close-button .close {
    background-color: var(--skin-primary-color-1);
}

h1.header.page-title,
h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert);
}

.product-tile {
    background-color: var(--skin-background-color-1);
    border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
    color: var(--skin-primary-color-1);
}

.custom-select {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-selectbox-text-color-1);
}

a {
    color: var(--skin-primary-color-1);
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: var(--skin-primary-color-1);
}

.price {
    color: var(--skin-price-1);
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
    color: var(--skin-primary-color-black);

    @media (min-width: 769px) {
        // stylelint-disable-line
       // color: var(--skin-menu-color-1-invert);
        color: var(--skin-primary-color-black);
    }
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
    color: var(--skin-selectbox-text-color-1);
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: var(--skin-selectbox-background-color-1);

    a,
    .nav-item {
        color: var(--skin-selectbox-text-color-1);
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    //background-color: var(--skin-menu-color-1-invert);
    color: var(--skin-menu-color-1) !important;
}

.btn-primary {
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
    color: var(--color-white);
    border-radius: 20px;
}

.btn-primary:hover {
    background-color: var(--skin-primary-color-2);
    border-color: var(--skin-primary-color-2);
    color: var(--color-white);
    border-radius: 20px;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color);
    color: var(--skin-primary-color-invert-1);
    opacity: 0.5;
    border-radius: 20px;
}

.btn-outline-primary {
    color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
    background-color: var(--color-white);
    border-radius: 20px;

}

.btn-outline-primary:hover {
    color: var(--color-white);
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
    border-radius: 20px ;   
}

.btn-textLink {
    color:var(--skin-primary-color-1);
}

